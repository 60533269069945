<template>
  <main class="mainlayout">
 
    <Sidebar/> 

    <div class="content">
      <div class="tabs" v-if="$store.getters.isAdmin">
        <a class="textbtn" :class="{selected: usersTab}" @click="usersTab = true">Users</a> <a class="textbtn" :class="{selected: !usersTab}" @click="usersTab = false">Organizations</a>
      </div>
    <div v-show="!usersTab">
      <div class="neworg">
        <button class="insead autowidth large left" :disabled="!newOrg.name || loading" @click="addOrganization">Add new organization</button>   
        <input v-model.trim="newOrg.name" type="text" :disabled="loading" autocomplete="off" placeholder="Organization name" /> 
        <input v-model.trim="newOrg.emailDomain" type="text" :disabled="loading" style="margin-left: 10px;" autocomplete="off" placeholder="Email domain (wihout the @)" />
      </div>
      <div style="color: grey; margin-top:10px; font-size: 14px;">Email domain example: "insead.edu" without the @ (this allows matching new users both from @insead.edu and any subdomains such as @it.insead.edu)</div>
      <div class="formerror" style="white-space: pre;" v-if="error">{{error}}</div>      
      <br>
      <input v-model.trim="orgSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <br>
      <br>
      <VueGoodTable
      ref="orgdatatable"
      :columns="orgColumns"
      :rows="organizations"
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: orgSearchTerm
      }"
      :select-options="{ 
        enabled: false
      }">
        
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">
            <button class="insead autowidth right" @click="editOrg(props.row.id)">Edit</button>
          </span>          
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>
    </div>
    <div v-show="usersTab">      
      <button v-if="showAddUsers" 
        :disabled="!newUsersList || newUsersList.length == 0 || loading" 
        class="insead autowidth left large"
        :class="{disabled: (!newUsersList || newUsersList.length == 0)}"
        @click="addUsers"
      >
        Invite {{newUsersList ? newUsersList.length : 0}} users
      </button>
      <button class="insead autowidth large left" :class="{white: showAddUsers}" @click="showAddUsers = !showAddUsers">{{!showAddUsers ? "Invite users" : "Cancel"}}</button>

      <select v-show="showAddUsers" class="select-css" style="float: left; width: auto; height: 44px;" v-model="newUsersRole">
          <option value="Student" selected>Student</option>
          <option value="Professor" selected>Professor</option>
          <option value="Admin" v-if="$store.getters.isAdmin" selected>Admin</option>
          <option value="OrgAdmin" v-if="$store.getters.isAdmin" selected>Organization Admin</option>
        </select>

      <select v-show="showAddUsers && $store.getters.isAdmin" class="select-css" style="float: left; width: auto; height: 44px; margin-left: 10px;" v-model="newUsersOrganizationId">
        <option :value=null selected>No Organization</option>
        <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
          {{org.name}}
        </option>
      </select>

      <div v-show="showAddUsers" style="padding-top:10px; clear: both;">
        <input type="radio" v-model="bulkInvite" :value="false" /> Single user
        <input type="radio" v-model="bulkInvite" :value="true" /> Bulk invite

        <input type="text" v-model="newUserEmail" v-show="!bulkInvite" placeholder="Email" />
        <input type="text" v-model="newUserFirstName" v-show="!bulkInvite" placeholder="First name" />
        <input type="text" v-model="newUserLastName" v-show="!bulkInvite" placeholder="Last name" />

        <textarea class="newusers" v-show="bulkInvite" v-model="newUsers" placeholder="Paste users here, one in each line in the following CSV format: Email, FirstName, LastName (maximum 20 can be invited in one step)"></textarea>
        <div v-if="newUsers || !bulkInvite">          
          Found <b>{{newUsersList ? newUsersList.length : 0}}</b> emails: {{ newUsersList && (newUsersList.length == 20) ? '(maximum 20 can be invited in one step)' : '' }}
          <br>
          <span class="newuseremail" v-for="user in newUsersList" :key="user.email">
            {{user[0]}} ({{user[1] ? user[1] : '***MISSING FIRST NAME***'}}, {{user[2] ? user[2] : '***MISSING LAST NAME***'}})
          </span>
        </div>        
      </div>

      <br style="clear: both;">
      <div class="formerror" style="white-space: pre;" v-if="error">{{error}}</div>
      <br>

      <a class="textbtn" @click="roleFilter = ''" :class="{selected: roleFilter == ''}">All users</a>
      <a class="textbtn" @click="roleFilter = Roles.Student" :class="{selected: roleFilter == 'Student'}">Students</a>
      <a class="textbtn" @click="roleFilter = Roles.Professor" :class="{selected: roleFilter == 'Professor'}">Professors</a>
      <a class="textbtn" v-if="$store.getters.isAdmin" @click="roleFilter = Roles.Admin" :class="{selected: roleFilter == 'Admin'}">Admins</a>
      <a class="textbtn" @click="roleFilter = Roles.OrgAdmin" :class="{selected: roleFilter == 'OrgAdmin'}">Organization Admins</a>
      <a class="textbtn" @click="roleFilter = 'provisional'" :class="{selected: roleFilter == 'provisional'}">Invitations</a>
      <span class="technicalcheckbox"><input type="checkbox" v-model="showTechnicalUsers">Show technical users</span>

      <br>
      <br>
      <input v-model.trim="searchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." /> 

      <br>
      <br>      
      <VueGoodTable
      ref="datatable"
      :columns="columns"
      :rows="filteredUsers"
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }">
        <template v-slot:selected-row-actions>  
          <button class="insead autowidth right" :disabled="loading || !selectedPreapprovedUsers.length" :class="{disabled: loading || !selectedPreapprovedUsers.length}" @click="removeUsers">Remove invitation</button>                  
          <button class="insead autowidth right" :disabled="loading || !selectedUnapprovedUsers.length" :class="{disabled: loading || !selectedUnapprovedUsers.length}" @click="activateUsers">Approve</button>                      
          <button class="insead autowidth right" :disabled="loading || !selectedUsers.length" :class="{disabled: loading || !selectedUsers.length}" @click="showModal = true">Edit roles</button>                      
          <button class="insead autowidth right" :disabled="loading || !selectedUsers.length || selectedUsers.filter(u => u.lastName == 'technical').length > 0 || selectedPreapprovedUsers.length > 0" @click="showUserOrgModal = true" v-if="$store.getters.isAdmin">Change organization</button> 
          <button class="insead autowidth right" :disabled="loading || !selectedUsers.length || selectedPreapprovedUsers.length > 0" @click="unassignAllSessionsBulk">Unassign Sessions</button>                          
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">
            <button class="insead autowidth right" @click="editRow(props.row.id)" :disabled="!props.row.id">Sessions</button>
            <button class="insead autowidth right" @click="requestPasswordReset(props.row.id)" :disabled="!props.row.id">Reset Password</button>
          </span>          
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>

      </div>
      <span class="spinner relative" v-if="loading" />
    </div>

    <Modal v-if="showModal" @close="showModal = false; error = ''" class="rolesmodal">      
      <h3 slot="header">Select roles</h3>
      <div slot="body">
        <div>
          <input type="checkbox" id="studentrolecheckbox" v-model="selectedRoles.student">
          <label for="studentrolecheckbox">Student</label>
        </div>
        <div>
          <input type="checkbox" id="professorrolecheckbox" v-model="selectedRoles.professor">
          <label for="professorrolecheckbox">Professor</label>
        </div>
        <div v-if="$store.getters.isAdmin">
          <input type="checkbox" id="adminrolecheckbox" v-model="selectedRoles.admin">
          <label for="adminrolecheckbox">Admin</label>  
        </div>    
        <div>
          <input type="checkbox" id="orgadminrolecheckbox" v-model="selectedRoles.orgAdmin">
          <label for="orgadminrolecheckbox">Organization Admin</label>  
        </div>   
      </div>
      <div slot="footer">
        <button class="insead" @click="setRoles" :class="{disabled: loading}" :disabled="loading">Set for selected users</button>
        <div class="formerror" v-if="error">{{error}}</div>
      </div>
    </Modal>    

    <Modal v-if="showOrgModal" @close="showOrgModal = false; error = ''" class="orgmodal">      
      <h3 slot="header">Edit organization</h3>
      <div slot="body">
        <label>Name</label>
        <input v-model.trim="tempOrg.name" type="text" placeholder="organization name"> 
        <label>Email domain</label>
        <input v-model.trim="tempOrg.emailDomain" type="text" placeholder="email domain (without the @)"> 
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="insead" @click="updateOrg" :disabled="!tempOrg.name || loading">Update</button>        
      </div>
    </Modal>  

    <Modal v-if="showUserOrgModal" @close="showUserOrgModal = false; error = ''" class="userorgmodal">      
      <h3 slot="header">Set organization</h3>
      <div slot="body">
        <select class="select-css" v-model="userOrgId">
          <option value=undefined selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select> 
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="insead" @click="updateUserOrg" :disabled="loading">Update</button>        
      </div>
    </Modal>  

    <Modal v-if="showUserSessionsModal" @close="showUserSessionsModal = false; error = ''" class="usersessionsmodal">      
      <h3 slot="header">Assigned sessions of {{tempUser.email}}</h3>
      <div slot="body">
        <table v-if="userSessions.length">
        <tr v-for="session in userSessions" :key="session.id"> 
          <td>{{session.id}}</td> 
          <td>{{session.name}}</td>
        </tr>
        </table>
        <span v-else>User has no sessions assigned</span>
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="insead" @click="unassignAllSessions" :disabled="loading || !userSessions.length">Remove from all sessions</button>        
      </div>
    </Modal>  

    <Modal v-if="showResetPasswordModal" @close="showResetPasswordModal = false; error = ''" class="resetpasswordmodal">      
      <h3 slot="header">Reset password for {{tempUser.email}}</h3>
      <div slot="body">
        This will generate a password reset token and send it in email for the user (it is equal as if typing the user's email in the reset password screen).
        <div class="formerror" v-if="error">{{error}}</div>         
      </div>
      <div slot="footer">
        <button class="insead" @click="resetPassword" :disabled="loading">Generate and send password reset token</button>        
      </div>
    </Modal> 

    <Snackbar ref="snackbar" /> 
  </main>
</template>

<script>
import axios from 'axios';

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Modal from '@/components/Modal.vue'
import Sidebar from '@/components/Sidebar.vue'
import {Roles} from '@/roles.js'
import Snackbar from '@/components/Snackbar.vue'
import Papa from 'papaparse'

export default {
  name: 'Users',
  data: function(){
    return {
      orgColumns: [
        {
          label: 'ID',
          field: 'id'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Email domain',
          field: 'emailDomain'
        },
        {
          label: 'Actions',
          field: 'actions'
        },
      ],
      columns: [
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Organization',
          field: 'organization.name',
          filterOptions: {
            //styleClass: 'class1', // class to be added to the parent th element
            enabled: this.$store.getters.isAdmin, // enable filter for this column
            placeholder: 'Filter by Org', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            // filterDropdownItems: experiences.map(e => e.name), // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Approved',
          field: 'approved',
        },
        {
          label: 'Confirmed',
          field: 'emailConfirmed',          
        },
        {
          label: 'First Name',
          field: 'firstName',
        },
        {
          label: 'Last Name',
          field: 'lastName',
        },        
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Roles',
          field: 'roles',
          // filterOptions: {
          // styleClass: 'class1', // class to be added to the parent th element
          //   enabled: true, // enable filter for this column
          //   placeholder: 'Filter This Thing', // placeholder for filter input
          //   //filterValue: 'Jane', // initial populated value for this filter
          //   filterDropdownItems: ['Admin','bbbb','ccccc'], // dropdown (with selected values) instead of text input
          //   //filterFn: this.columnFilterFn, //custom filter function that
          //   //trigger: 'enter', //only trigger on enter not on keyup 
          // }
        },
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Actions',
          field: 'actions'
        }
      ],
      roleFilter: '',
      users: [],
      organizations: [],
      userSessions: [],
      tempUser: undefined,
      loading: false,
      error: undefined,
      searchTerm: undefined,
      orgSearchTerm: undefined,
      showAddUsers: false,
      bulkInvite: false,
      newUserEmail: undefined,
      newUserFirstName: undefined,
      newUserLastName: undefined,
      newUsers: '',
      showModal: false,
      showOrgModal: false,
      showUserOrgModal: false,
      showUserSessionsModal: false,
      showResetPasswordModal: false,
      selectedRoles: {
        student: false,
        professor: false,
        admin: false,
        orgAdmin: false
      },
      newUsersRole: 'Student',
      newUsersOrganizationId: null,
      showTechnicalUsers: false,
      newOrg: {
        name: undefined,
        emailDomain: undefined
      },
      tempOrg: {
        name: undefined,
        emailDomain: undefined
      },
      usersTab: true,
      userOrgId: undefined
    }
  },
  computed: {  
    filteredUsers(){
      return this.roleFilter 
        ? this.users.filter(u => (this.showTechnicalUsers || u.lastName != "technical") && (this.roleFilter == 'provisional' ? !u.id : (u.roles && u.roles.includes(this.roleFilter)))) 
        : this.users.filter(u => this.showTechnicalUsers || u.lastName != "technical");
    },
    newUsersList(){      
      return (this.bulkInvite ? Papa.parse(this.newUsers, {header: false, skipEmptyLines: true}).data : [[this.newUserEmail, this.newUserFirstName, this.newUserLastName]])
          .map(d => [d[0]?.trim()?.toLowerCase(), d[1]?.trim(), d[2]?.trim()] )
          // eslint-disable-next-line 
          .filter(d => /^[\w-\.]+[+]?[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,}$/g.test(d[0])).slice(0, 20)
    },
    selectedUnapprovedUsers(){
      return this.$refs.datatable.selectedRows.filter(u => !u.approved).map(u => u.email);
    },
    selectedPreapprovedUsers(){
      return this.$refs.datatable.selectedRows.filter(u => u.approved && !u.id).map(u => u.email);
    },
    selectedUsers(){
      return this.$refs.datatable.selectedRows;
    }
  },
  methods: {   
    async unassignAllSessionsBulk(){
      if(!confirm('Are you sure you want to bulk unassign all sessions of selected users?'))
        return
      try{
        this.$nprogress.start()
        this.loading = true   
        this.error = undefined;
        for (let index = 0; index < this.selectedUsers.length; index++) {
          await axios.delete(`users/${this.selectedUsers[index].id}/sessions`)            
        }    
      }
      catch(err){
        console.log("Error when bulk unassigning sessions: " + err)
        this.$refs.snackbar.show('Error when bulk unassigning sessions: ' + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
        this.userSessions = [] 
      }
    },
    async editRow(id){            
      let resp = await axios.get(`users/${id}/sessions`)
      this.userSessions = resp.data
      this.tempUser = this.users.find(u => u.id == id)
      this.showUserSessionsModal = true
    },     
    async unassignAllSessions(){
      await axios.delete(`users/${this.tempUser.id}/sessions`)
      this.userSessions = []
    },
    async requestPasswordReset(id){      
      this.tempUser = this.users.find(u => u.id == id)
      this.showResetPasswordModal = true
    }, 
    async resetPassword(){      
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios({ url: "auth/requestpasswordreset?email=" + encodeURIComponent(this.tempUser.email), method: "POST" }); 
        if(resp.data){
          this.$refs.snackbar.show('Password reset token successfully sent')
          this.showResetPasswordModal = false
        }
        else{
          this.error = 'Error when sending token in email. Please try again, and if the issue persists, please report it to the tech team.';
          this.$refs.snackbar.show('Error when sending token in email.')
        }
      }
      catch(err){     
        this.$refs.snackbar.show('Error when generating token: ' + err.response.data)
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }, 
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data

      // set filters
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'organization.name'
      })        
      this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.organizations.map(o => o.name));
    },
    async addOrganization(){
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios.post(`organizations`, this.newOrg)
        this.organizations.push(resp.data)
        this.newOrg =  {
          name: undefined,
          emailDomain: undefined
        }
      }
      catch(err){
        console.log("Error when adding organization: " + err.response.data)
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async editOrg(id){      
      this.tempOrg = this.organizations.find(s => s.id == id)      
      this.showOrgModal = true       
    }, 
    async updateOrg(){      
      try{        
        this.error = undefined
        this.$nprogress.start()
        this.loading = true        
        let resp = await axios.post(`organizations/${this.tempOrg.id}`, this.tempOrg)
        // console.log(resp.data)      
        this.tempOrg.name = resp.data.name
        this.tempOrg.emailDomain = resp.data.emailDomain
        this.showOrgModal = false
      }
      catch(err){
        console.log("Error when updating organization: " + err.response.data);
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }, 
    async fetchUsers(){
      try{
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios({ url: "users", method: "GET" }); 
        this.users = resp.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async addUsers(){
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        // eslint-disable-next-line
        let resp = await axios({ url: `users?role=${this.newUsersRole}&organizationId=${this.newUsersOrganizationId ?? ''}`, data: this.newUsersList, method: "POST" }); 
        this.newUsers = '';
        this.showAddUsers = false;

        if(resp.data && resp.data.length){
          this.error = "Invitations created, but a problem occurred while sending for the following emails: " + resp.data.join(', ') + "\r\nCheck if the invitation exists in the Invitations tab, and to be sure please try to notify these invited user(s) separately.";
          this.$refs.snackbar.show('A problem occurred when sending out some of the invitation emails.')
        }
        else{
          this.$refs.snackbar.show('Invitation sent successfully!')
          this.newUserEmail = this.newUserFirstName = this.newUserLastName = undefined
        }
        
        this.fetchUsers();
      }
      catch(err){
        console.log("Error when inviting users: " + err.response);
        this.$refs.snackbar.show('An unexpected problem occurred when inviting users.')
        this.error = err.response.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async activateUsers(){
      try{
        this.$nprogress.start();
        this.loading = true;   
        this.error = undefined;    
        await axios({ url: "users/approve", data: this.selectedUnapprovedUsers, method: "POST" });
        this.fetchUsers();        
      }
      catch(err){
        console.log("Error when approving users: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async setRoles(){
      try{
        this.$nprogress.start();
        this.loading = true;  
        this.error = undefined;

        let rolesArray = [];
        if(this.selectedRoles.student) 
          rolesArray.push(Roles.Student)
        if(this.selectedRoles.admin) 
          rolesArray.push(Roles.Admin)
        if(this.selectedRoles.orgAdmin) 
          rolesArray.push(Roles.OrgAdmin)
        if(this.selectedRoles.professor) 
          rolesArray.push(Roles.Professor)

        await axios({ url: "users/setroles", data: {
          emails: this.selectedUsers.map(e => e.email),
          roles: rolesArray
        }, method: "POST" });
        this.showModal = false;
        this.fetchUsers();        
      }
      catch(err){
        console.log("Error when applying user roles: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async updateUserOrg(){
      try{
        this.$nprogress.start();
        this.loading = true;  
        this.error = undefined;

        await axios({ url: "users/setorganization", data: {
          userIds: this.selectedUsers.map(e => e.id),
          organizationId: this.userOrgId
        }, method: "POST" });
        this.showUserOrgModal = false;
        this.fetchUsers();        
      }
      catch(err){
        console.log("Error when updating organization for users: " + err.response?.data);
        this.error = err.response?.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },
    async removeUsers(){
      try{
        this.$nprogress.start();
        this.loading = true;     
        this.error = undefined;  
        await axios({ url: "users", data: this.selectedPreapprovedUsers, method: "DELETE" });
        this.fetchUsers();        
      }
      catch(err){
        console.log("Error when removing approved users: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }
  },  
  components: {
    VueGoodTable, Modal, Snackbar, Sidebar
  },
  mounted() {
    this.fetchUsers();
    this.getOrganizations();
  },
  created() {
      this.Roles = Roles;
  }
}
</script>

<style lang="scss">

.tabs{ 
  margin-bottom: 15px;
}

.neworg{
  display: flex;
  input{
    flex-grow: 1;
  }
  button{
    flex: none;
  }
}

.technicalcheckbox{
  margin-left: 20px;
  font-size: 14px;
  color: $july;
  
  input{
    vertical-align: bottom;
    margin-right: 5px;
    cursor: pointer;
  }
}

.rolesmodal{ 
  label, input[type=checkbox]{
    float: none;
    vertical-align: middle;
    cursor: pointer;
  }
  label {
    padding-left: 5px;
    &:hover{
      color: $green1;
    }
  }
}

textarea.newusers{
  margin-top: 10px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
}

span.newuseremail{
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 5px;
  background-color: $green2;
  color: white;
}

.rowActions{
  display: flex;
  opacity: 0.0;
}

.vgt-table tr:hover .rowActions{
  opacity: 1;
}

.modal-container{
  width: 600px;
}

</style>
